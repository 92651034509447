<template>
  <div class="card-body">
    <div class="text-center">
      <h4>{{ $t('views.constants.signIn') }}</h4>
      <h6>{{ $t('views.constants.signInMessage') }}</h6>
    </div>
    <app-form :fields="fields" :submit-button="submitButton" @submitted="handleSubmit" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      fields: {
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.user.attributes.email'),
          required: true,
          valid: false,
          classes: 'col-12 py-0'
        },
        password: {
          type: 'password',
          key: 'password',
          value: '',
          label: this.$t('models.user.attributes.password'),
          required: true,
          valid: false,
          minLength: 6,
          classes: 'col-12 py-0'
        }
      },
      submitButton: {
        label: this.$t('actions.login'),
        block: true
      }
    };
  },
  methods: {
    ...mapActions(['login']),
    handleSubmit(data) {
      this.login(data);
    }
  }
};
</script>
